@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
        url('/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
        url('/fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('/fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
        url('/fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
        url('/fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Medium'), local('OpenSans-Medium'),
        url('/fonts/OpenSans/OpenSans-Medium.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Medium Italic'), local('OpenSans-MediumItalic'),
        url('/fonts/OpenSans/OpenSans-MediumItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
        url('/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
        url('/fonts/OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
        url('/fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

