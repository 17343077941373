$shadow-image: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
$shadow-image-hover: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
$shadow-button:rgba(0, 0, 0, 0.08) 0px 4px 12px;
$shadow-button-hover: rgba(0, 0, 0, 0.15) 0px 2px 8px;
//complete ^ 


$shadow-input: 0px 0px 10px rgba(0,0,0,0.2);
$shadow-input-hover: 0px 0px 10px rgba(0,0,0,0.2);

$shadow-nav: 0px 0px 10px rgba(0,0,0,0.2);
$shadow-nav-hover: 0px 0px 10px rgba(0,0,0,0.2);


$shadow-standard: 0px 0px 10px rgba(0,0,0,0.2);
$shadow-standard-hover: 0px 0px 10px rgba(0,0,0,0.2);




