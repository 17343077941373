@use "./variables/text" as fonts;
@use "./variables/colors" as colors;
.navigation {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  .navigation__list {
    margin-left: auto;
    margin-right: 2em;
    display: flex;
    list-style: none;
    align-items: center;
    z-index: 1;
  }
  .navigation__logo {
    margin-left: 1em;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: fonts.$font-family-code;
    z-index: 2;
  }
  .navigation__item {
    font-size: 1em;
    font-weight: 700;
    font-family: fonts.$font-family-subheader;
    margin-left: 1rem;
    cursor: pointer;
  }
  .navigation__toggle {
    display: none;
    cursor: pointer;
  }
  .navigation__theme-button {
    background-color: transparent;
    width: 50px;
  }
}
@media screen and (max-width: 768px) {
  .navigation {
    background-color: inherit;

    .navigation__logo {
      margin-left: 0;
    }
    .navigation__list {
      width: 100%;
      height: fit-content;
      display: block;
      background-color: inherit;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 99 !important;
    }
    .navigation__item {
      text-align: center;
      display: block;
      height: 50px;
      line-height: 50px;
      padding: 0 1rem;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }
    .navigation__link {
      width: 100%;
    }
    .navigation__toggle {
      display: block;
      border: none;
      background: transparent;
      margin-left: auto;
      margin-right: 2em;
    }
    .navigation__theme-button {
      right: 5em;
      z-index: 99;
    }
  }
}
