@use "../variables" as *;
.page__hero {
  display: block;
  overflow: hidden;
  padding-top: 2em;
  border-bottom: 2px solid $border-color;
  padding-bottom: 1em;
}
.page__hero-content {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.post__author,
.card__author {
  font-size: 1.2em;
  font-family: "Open Sans SemiCondensed", sans-serif;
  span {
    // transition: color 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: $border-color;
  }
}
.dark .post__author,
.dark .card__author {
  span {
    color: $accent-color;
    // transition: color 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
}
.post__tags {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  align-items: center;
  border-radius: 0.2em;
  padding: 0.1em;
  .post__tag {
    font-weight: 600;
    font-size: 0.8em;
    font-family: "Open Sans SemiCondensed", sans-serif;
    margin: 0 0.2em 0.5em 0;
  }
  svg {
    margin-right: 0.5em;
  }
}
.post__body-image {
  width: 75%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 0.5em 0;
  border-bottom: 2px solid $border-color;

  span {
    font-weight: 600;
    font-size: 0.8em;
    font-family: "Open Sans SemiCondensed";
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}
figcaption {
  font-size: 0.8em;
  font-family: "Open Sans SemiCondensed";
  top: 0;
  margin: 0;
  padding: 0.5em 0 0 0;
}

blockquote p {
  padding: 1em;
  border-radius: 0.2em;
  margin-left: 16px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-left: 0.25em solid #57606a;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
code {
  font-size: 0.75em !important;
  font-family: "Fira Code", monospace !important;
  padding: 1em !important;
  font-weight: 500;
}
pre {
  font-size: 1em !important;
  font-family: "Fira Code", monospace !important;
  padding: 1em !important;
  font-weight: 500;
}

.articleCard__container {
  width: 60%;
  height: 100%;
  border-radius: 0.2em;
  margin: 1em auto 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.articleCard__title {
  padding: 1em;
  border-top: 2px solid $border-color;
}
.card__image {
  border-radius: 0.2em 0.2em 0 0;
}
.card__more {
  width: 100%;
  text-align: right;
  a {
    margin: 0.5em;
  }
}
.post__body {
  .post__list {
    list-style: none;
    align-items: center;
    font-family: "Open Sans SemiCondensed", sans-serif;
    font-size: 1em;
    display: inline-block;
    align-items: center;
    .post__list-item {
      position: relative;
    }
  }
}
.code__container {
  position: relative;
  width: 100%;
  height: auto;
  .copy__button {
    position: absolute;
    top: 0.375em;
    right: 0.375em;
    background-color: transparent;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2em;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      filter: brightness(0.5);
    }
    .copied {
      fill: green;
      &:hover {
        fill: green;
      }
    }
  }
}

.scroll-to-top {
  cursor: pointer;
  z-index: 1;
  float: right;
  bottom: 1em;
  right: 2.5em;
}
@media screen and (max-width: $laptop) {
  .articleCard__container {
    width: 70%;
  }
}

@media screen and (max-width: $tablet) {
  .articleCard__container {
    width: 80%;
  }
}

@media screen and (max-width: $mobile) {
  .articleCard__container {
    width: 95%;
  }
}
