@font-face {
    font-family: 'Open Sans Condensed';
    src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
        url('/fonts/OpenSans-Condensed//OpenSansCondensed-Light.woff2') format('woff2'),
        url('/fonts/OpenSans-Condensed//OpenSansCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'),
        url('/fonts/OpenSans-Condensed//OpenSansCondensed-Bold.woff2') format('woff2'),
        url('/fonts/OpenSans-Condensed//OpenSansCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: local('Open Sans Condensed Light Italic'), local('OpenSansCondensed-LightItalic'),
        url('/fonts/OpenSans-Condensed//OpenSansCondensed-LightItalic.woff2') format('woff2'),
        url('/fonts/OpenSans-Condensed//OpenSansCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

