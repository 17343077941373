$text-size-header: 2.5rem;
$text-size-subheader: 1.5rem;
$text-size-body: 1.25rem;
$text-size-code: 1.125rem;
$text-size-small: 0.875rem;

$font-family-header: 'Ubuntu', sans-serif;
$font-family-subheader: 'Open Sans Condensed', sans-serif;
$font-family-body: 'Open Sans SemiCondensed', sans-serif;
$font-family-code:  "Fira Code", monospace;

