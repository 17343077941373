@use "./layout" as *;
@use "./components/form";
@use "./fonts/" as *;
@use "./variables/colors" as *;
@use "./variables/text" as *;
@use "./variables/breakpoints" as *;
@use "./variables/shadows" as *;
@forward "./reset";
@forward "./pages/about";
@forward "./layout/";
@forward "./article/article"; // html,
a {
  // color: inherit;
  color: currentColor;
  text-decoration: none;
  border-bottom: 1px solid $border-color;
  &:hover {
    border-bottom: 2px solid currentColor;
  }
}

h1 {
  font-weight: 500;
  font-family: $font-family-header;
  font-size: 4rem;
}
h2 {
  font-weight: 700;
  font-family: $font-family-subheader;

  font-size: 2.5rem;
}
h3 {
  font-weight: 700;
  font-family: $font-family-subheader;
  font-size: 2rem;
}
p {
  font-weight: 400;
  font-family: $font-family-body;
  font-size: 1.2rem;
}
a:active,
button:active {
  transform: scale(0.99);
}
svg {
  fill: #18181a;
}

.app__container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f9f9f9;
  color: #18181a;
}
.page__container {
  width: 60%;
  position: relative;
  min-height: calc(100vh - 50px);
  margin: auto;
  padding-bottom: 1em;
}
.page__header {
  span {
    font-size: 2rem;
    font-weight: 700;
    font-family: $font-family-subheader;
  }
}

.dark {
  a{
    color: #fff;
  }
  .app__container {
    background-color: #18181a;
    color: #f9f9f9;
  }
  svg {
    fill: #f9f9f9;
  }
}
.page__hero-content {
  max-width: 1600px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: $laptop) {
  .page__container {
    width: 75%;
  }
}

@media screen and (max-width: $tablet) {
  .page__container {
    width: 85%;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
  code {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: $mobile) {
  .page__container {
    width: 95%;
  }
}
