@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
        url('/fonts/Ubuntu/Ubuntu-LightItalic.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Light'), local('Ubuntu-Light'),
        url('/fonts/Ubuntu/Ubuntu-Light.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
        url('/fonts/Ubuntu/Ubuntu-Italic.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
        url('/fonts/Ubuntu/Ubuntu-Medium.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
        url('/fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
        url('/fonts/Ubuntu/Ubuntu-MediumItalic.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
        url('/fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
        url('/fonts/Ubuntu/Ubuntu-BoldItalic.woff2') format('woff2'),
        url('/fonts/Ubuntu/Ubuntu-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

